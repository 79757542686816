.footer-sec {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0%;
  padding: 2rem 3rem;
  margin-top: 2rem;
  overflow: hidden;
}

.footer-content {
  max-width: 646px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-top {
  /* flex-basis: 15%; */
  text-align: center;
  /* margin-bottom: 1rem; */
}

.footer-main-content {
  display: flex;
  justify-content: space-evenly;
}

.footer-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #740001;
}

.footer-list {
  list-style: none;
  padding: 0;
}

.footer-item {
  margin-bottom: 1rem;
}

.footer-link-title {
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  color: #740001;
}

.footer-link {
  color: #ae0001;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  text-align: justify;
}

.footer-link svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  fill: #ae0001;
}

.footer-phone-numbers {
  list-style: none;
  padding: 0;
}

.footer-social-links {
  list-style: none;
  padding: 0;
}

.color-maroon {
  color: #740001;
}
.color-reddish {
  color: #ae0001;
}

.footer-social-link {
  /* margin-left: 1rem; */
}

.map-container {
  margin: 2%;
  width: 800px;
}
.google-map iframe {
  height: 400px;
  border-radius: 10px;
}

@media screen and (width <= 768px) {
  .footer-main-content {
    width: 75vw;
    flex-direction: column;
  }
  .footer-top {
    flex-basis: 100%;
  }
  .map-container {
    width: 100%;
  }
  .footer-phone-numbers {
    font-size: 0.8rem;
  }
  .footer-link svg {
    width: 1rem;
    height: 1rem;
  }
  .address-svg svg {
    width: 3rem;
    height: 3rem;
  }
  .google-map iframe {
    height: 300px;
  }
}
