
.counter-section{
    width: 100%;
    height: 100vh;
    /* background: url(background.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */

    display: grid;
    grid-template-columns: 70%;
    justify-content: center;
    align-content: center;
}
.heading-counter{
    color: #740001;
    text-align: center;
    letter-spacing: 2px;
    word-spacing: 2px;
    margin-bottom: 25px;
}
.description-counter{
    color: #ae0001;
    text-align: center;
    letter-spacing: 1px;
}
.timer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.sub_timer{
    width: 220px;
    background: rgba(255, 255, 255, 0.19);
    backdrop-filter: blur(20px);
    border-radius: 20px;
    overflow: hidden;
}
.digit{
    color: #740001;
    font-weight: lighter;
    font-size: 70px;
    text-align: center;
    padding: 2.5rem 0;
    font-family: harry;
}
.digit_name{
    color: #ae0001;
    background: #fff;
    text-align: center;
    padding: 0.6rem 0;
    font-size: 20px;
}

@media screen and (width <= 768px) {
	.counter-section{
		grid-template-columns: 90%;
	}
	.sub_timer{
		margin: 0.3rem;
	}
	.digit{
		padding: 0.1rem;
	}
	.digit_name{
		font-size: 15px;
	}
}