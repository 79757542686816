
.sponsors-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 50px;
  }
  .sponsors-heading {
	font-size: 3.3rem;
	font-weight: bold;
	margin-bottom: 30px;
	color: #740001;
  }
  
  .sponsors-list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 80%;
	list-style: none;
	padding: 0;
	margin: 0;
	
}

.sponsors-item {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10px;
	padding: 30px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 1.2rem;
	/* flex: 1; */
	text-align: center;
	transition: all .5s ease-in-out;
}

  .sponsors-item:hover{
	  background-color: #f2aa4c;
	  color: white;
	  margin: 1%;
	}
	
	.sponsor-image {
		width: 100%;
		max-width: 200px;
		border-radius: 5px;
		margin-bottom: 10px;
	}
	
	.sponsor-title {
		font-size: 1.2rem;
		font-weight: bold;
		text-align: center;
		color: #ae0001;
		
	}
	.sponsors-sub-heading {
		font-size: 1.5rem;
		font-weight: bold;
		margin-bottom: 15px;
		margin-top: 30px;
		color: #740001;
	}
	.titlesp{
		font-size: 2.3rem;
	}
	.powered{
		font-size: 1.9rem;
	}
	.title-items{
		width: 50%;
		height: 50%;
	}
	.title-items img{
		max-width: 600px;
	}
	.pow-items{
		width: 30%;
	}
	.pow-items img{
		max-width: 220px;
	}

@media screen and (width <= 768px) {
	.sponsors-item{
		flex: 1;
	}
	.co-power{
	width: 70%;
	}
	
}