@font-face {
  font-family: harry;
  src: url("/public/Font/HARRYP__.TTF");
}
@font-face {
  font-family: img-text;
  src: url("/public/Font/harry_potter_and_the_dingbat_font.TTF");
}
.imgFont {
  font-family: img-text;
}
.harry {
  font-family: harry;
}
.header {
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.card {
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-family: harry;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: 10;
  color: #fff010;
}
.card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 5;
}
.event {
  position: absolute;
  top: 30%;
  left: 20%;
  z-index: 15;
}

.relative {
  position: relative;
}

.animated-paragraph {
  position: relative;
  font-size: 14px;
  line-height: 1.5;
  padding: 0;
}

.animated-paragraph::before,
.animated-paragraph::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: white;
  bottom: 0;
  transition: width 0.2s ease;
}

.animated-paragraph::before {
  left: 50%;
  transform: translateX(-50%);
}

.animated-paragraph::after {
  right: 50%;
  transform: translateX(50%);
}

.animated-paragraph:hover::before,
.animated-paragraph:hover::after {
  width: 100%;
}

.current {
  position: relative;
  font-size: 14px;
  line-height: 1.5;
  padding: 0;
}
.current::before,
.current::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: white;
  bottom: 0;
  transition: width 0.2s ease;
}

.blur-background {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px); /* Adjust the blur intensity as needed */
  z-index: -1;
}

.timeline-container {
  position: relative;
  z-index: 20; /* Ensure it is below the timeline for the blur effect to work */
}
.centering {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.sch{
  position: absolute;
  transform: rotate(270deg);
  top: -16rem;
  left: 0rem;
  font-size: 3rem;
}

@media screen and (width <= 768px) {
  .event{
    top:35%;
  }
  .card{
    background-position: 1%;
    background-size: 100% 40%;
    background-color: #d3a625;
  }
  .card::after{
	opacity: 0.5;
  }
  .animated-paragraph {
    line-height: 1;
  }
  .animated-paragraph::after,
  .animated-paragraph::before {
    height: 1.5px;
  }
  .current {
    line-height: 1;
  }
  .current::after,
  .current::before {
    bottom: -3.5px;
  }
  .sch{
    top: -10.5rem;
    left: 2rem;
	font-size: x-large;
  }

}

