.main-section{
    background-image: url(/src/images/Assets/bg.webp);
    overflow: hidden;
}
.main{
    position: relative;
    display: flex;
    flex-direction: row;
}

.img1{
    width: 100%;
    border-radius: 7px;
}

.logo-img{
    width: 70%;
    z-index: 1;
}

.mid-cloud{
    position: absolute;
    top: 0%;
    width: 100%;
    height: 80%;
    z-index: 18;
}

.corner-cloud-left{
    position: absolute;
    top: -1.5%;
    left: -6%;
    width: 95%;
    transform: rotate(180deg);
    z-index: 20;
}
.corner-cloud-right{
    position: absolute;
    bottom: -1.5%;
    right: -6%;
    width: 95%;
    z-index: 20;
}
.round-cloud{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.mid-cloud-2nd{
    position: absolute;
    top: -40%;
    left: -50%;
    max-width: 120rem;
    z-index: 0;
}
.mid-cloud-1st{
    position: absolute;
    top: -23%;
    width: 100%;
    z-index: 3;
}
.darkest{
    position: absolute;
    top: -25%;
    left: -10%;
    width: 100%;
    z-index: 2;
}
.light{
    position: absolute;
    top: -13%;
    z-index: 0;
}
.collage{
    top: 30%;
    z-index: 9;
}
div h1.right-0{
    right: -10%;
}

.poster{
    display: flex;
    flex-direction: row;
    margin: auto;
    padding: 2rem;
}
.poster-img{
    width: 50%;
}
.poster-section{
    background-repeat: no-repeat;
    background-size: 100% 123%;
}
.content{
    position: relative;
    width: 50%;
    padding: 3rem;
    text-align: justify;
}
.btn-veve{
    position: absolute;
    right: 38%;
    bottom: 0%;
    background-color: #740001;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    padding: 10px;
    border-radius: 1rem;
}
.divider-parent{
	display: flex;
	justify-content: center;
	align-items: center;
    overflow: hidden;
}
.divider-child img{
	position: relative;
	max-width: 170rem;
}
.nd2{
    transform: rotate(180deg);
}
@media screen and (width <= 768px) {
.main-section{
    overflow: hidden;
}
	.mid-cloud{
		width: 100vw;
		height: 100vh;
	}
	
	.corner-cloud-left{
		width: 50rem;
        max-width: 100rem;
		height: 70vh;
	}
	.corner-cloud-right{
        width: 50rem;
        max-width: 100rem;
		height: 70vh;
	}
	.logo-img{
		width: 100vw;
		z-index: 1;
	}
	.darkest{
		width: 100rem;
        max-width: 120rem;
		top: 15vh;
		left: -70vh;
	}
    .mid-cloud-2nd{
        top: -30%;
        left: -180vw;
        width: 100rem;
        max-width: 100rem;
    }
    .light{
        top: 0;
        left: -10rem;
        width: 50rem;
        max-width: 100rem;
    }
    .poster-section{
        background-size: 100% 130%;
    }
    .btn-veve{
        font-size: 1rem;
        right: 29%;
        bottom: -8%;
    }
	.poster{
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;
		padding: 2rem;
	}
	.poster-img{
		width: 100%;
	}
	.content{
		width: 100%;
		padding: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: justify;
	}
    .divider-child img{
        margin-top: 1rem;
        max-width: 80rem;
    }
}
