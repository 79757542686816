@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
    margin: 0;
    /* background-color: #000; */
    color: #eee;
    font-family: Poppins;
    font-size: 12px;
}
a{
    text-decoration: none;
}

header{
    width: 1140px;
    max-width: 80%;
    margin: auto;
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 100;
}
header a{
    color: #eee;
    margin-right: 40px;
}
/* carousel */
.carousel{
    height: 107vh;
    margin-top: -50px;
    width: 100vw;
    overflow: hidden;
    position: relative;
}
.carousel .list .item{
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 0 0 0;
}
.carousel .list .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.carousel .list .item .content{
    position: absolute;
    top: 20%;
    width: 50%;
    max-width: 80%;
    left: 35%;
    transform: translateX(-50%);
    padding: 1%;
    box-sizing: border-box;
    color: #fff;
    text-shadow: 0 5px 10px #0004;
    background-color: rgba(0, 0, 0, .3);  
    backdrop-filter: blur(2px);

}
.carousel .list .item .author{
    font-weight: bold;
    letter-spacing: 10px;
	padding-right: 20px;

}
.carousel .list .item .title,
.carousel .list .item .topic{
    font-size: 5em;
    font-weight: bold;
    line-height: 1.3em;
	padding-right: 20px;
	text-align: left;
}
.carousel .list .item .topic{
    color: #d3a625;
    font-size: 3em;

}
.carousel .list .item .buttons{
    display: grid;
    grid-template-columns: repeat(2, 130px);
    grid-template-rows: 40px;
    gap: 5px;
    margin-top: 20px;
	padding-right: 20px;
	
}
.carousel .list .item .buttons button{
	border: none;
    background-color: #eeba30;
    letter-spacing: 3px;
    font-family: Poppins;
    font-weight: 500;
	transition: all 0.3s ease-in-out;
}
.carousel .list .item .buttons button:hover{
    background-color: #fff;
    color: #eeba30;
}
.carousel .list .item .buttons button:nth-child(2){
	background-color: transparent;
    border: 1px solid #fff;
    color: #eeba30
}
.carousel .list .item .buttons button:nth-child(2):hover{
    background-color: #fff;
}
/* thumbail */
.thumbnail{
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: max-content;
    z-index: 100;
    display: flex;
    gap: 20px;
}
.thumbnail .item{
    width: 150px;
    height: 220px;
    flex-shrink: 0;
    position: relative;
}
.thumbnail .item:nth-child(1){
    border-radius: 20px;
    box-shadow: 0 0 25px 10px rgb(255, 255, 19);

}
.thumbnail .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.thumbnail .item .content{
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: -10px;
    right: 10px;
	text-align: left;
}
.thumbnail .item .content .title{
    font-weight: 500;
}
.thumbnail .item .content .description{
    font-weight: 300;
}
/* arrows */
.arrows{
    position: absolute;
    top: 73%;
    right: 62%;
    z-index: 100;
    width: 300px;
    max-width: 30%;
    display: flex;
    gap: 40px;
    align-items: center;
}
.arrows button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    padding: 20px;
    border-radius: 50%;
    /* background-color: #eee4; */
    border: none;
    color: #fff;
    font-family: monospace;
    font-weight: bold;
    transition: .5s;
}
.arrows button:hover{
    background-color: #fff;
    color: #000;
}

/* animation */
.carousel .list .item:nth-child(1){
    z-index: 1;
}

/* animation text in first item */

.carousel.next .list .item:nth-child(1) .content .author,
.carousel.next .list .item:nth-child(1) .content .title,
.carousel.next .list .item:nth-child(1) .content .topic,
.carousel.next .list .item:nth-child(1) .content .des,
.carousel.next .list .item:nth-child(1) .content .buttons
{
    transform: translateY(50px);
    filter: blur(20px);
    opacity: 0;
    animation: showContent .5s  linear 1 forwards;
}
@keyframes showContent{
    to{
        transform: translateY(0px);
        filter: blur(0px);
        opacity: 1;
    }
}
.carousel .list .item:nth-child(1) .content .title{
    animation-delay: 1s!important;
}
.carousel .list .item:nth-child(1) .content .topic{
    animation-delay: 1.2s!important;
}
.carousel .list .item:nth-child(1) .content .des{
    animation-delay: 1.4s!important;
}
.carousel .list .item:nth-child(1) .content .buttons{
    animation-delay: 1.6s!important;
}
/* create animation when next click */
.carousel.next .list .item:nth-child(1) img{
    width: 150px;
    height: 220px;
    position: absolute;
    bottom: 50px;
    left: 50%;
    border-radius: 30px;
    animation: showImage .5s linear 1 forwards;
}
@keyframes showImage{
    to{
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

.carousel.next .thumbnail .item:nth-last-child(1){
    overflow: hidden;
    animation: showThumbnail .5s linear 1 forwards;
}
.carousel.prev .list .item img{
    z-index: 100;
}
@keyframes showThumbnail{
    from{
        width: 0;
        opacity: 0;
    }
}
.carousel.next .thumbnail{
    animation: effectNext .5s linear 1 forwards;
}

@keyframes effectNext{
    from{
        transform: translateX(150px);
    }
}

/* running time */

.carousel .time{
    position: absolute;
    z-index: 1000;
    width: 0%;
    height: 10px;
    background-color: #eeba30;
    left: 0;
    top: 6%;
}

.carousel.next .time,
.carousel.prev .time{
    animation: runningTime 3s linear 1 forwards;
}
@keyframes runningTime{
    from{ width: 100%}
    to{width: 0}
}


/* prev click */

.carousel.prev .list .item:nth-child(2){
    z-index: 2;
}

.carousel.prev .list .item:nth-child(2) img{
    animation: outFrame 0.5s linear 1 forwards;
    position: absolute;
    bottom: 0;
    left: 0;
}
@keyframes outFrame{
    to{
        width: 150px;
        height: 220px;
        bottom: 50px;
        left: 50%;
        border-radius: 20px;
    }
}

.carousel.prev .thumbnail .item:nth-child(1){
    overflow: hidden;
    opacity: 0;
    animation: showThumbnail .5s linear 1 forwards;
}
.carousel.next .arrows button,
.carousel.prev .arrows button{
    pointer-events: none;
}
.carousel.prev .list .item:nth-child(2) .content .author,
.carousel.prev .list .item:nth-child(2) .content .title,
.carousel.prev .list .item:nth-child(2) .content .topic,
.carousel.prev .list .item:nth-child(2) .content .des,
.carousel.prev .list .item:nth-child(2) .content .buttons
{
    animation: contentOut 1.5s linear 1 forwards!important;
}

@keyframes contentOut{
    to{
        transform: translateY(-150px);
        filter: blur(20px);
        opacity: 0;
    }
}

.list-container{
    background-color: rgba(0, 0, 0, 0.3); 
    backdrop-filter: blur(15px); 
    font-size: 1rem;
    font-weight: 900;
    padding: 40px;
    cursor: pointer;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.event-lst{
    position: absolute;
    top: 40%;
    transform: rotate(-90deg);
    font-size: 3rem;
    left: 60%;
}
.color-maroon{
	color: #740001;
  }
  .color-reddish{
	color: #ae0001;
}

@media screen and (max-width: 678px) {
    .carousel .list .item .content{
        padding-right: 0;
    }
    .carousel .list .item .content .title{
		text-align: center;
        font-size: 2.5em;
    }
	.carousel .list .item .topic{
		font-size: 1.8em;
		font-weight: bold;
		line-height: 1.3em;
	}
	.carousel .list .item .content{
		top: 15%;
		left: 50%;
		width: 80%;
		padding: 3%;
	}
	.event-lst{
		font-size: 1.5rem;
		left: 70%;
		top: 45%;
	}
	.list-container{
		padding: 30px;
		font-size: 0.8rem;
		width: 11rem;
	}
	.thumbnail{
		bottom: 80px;
	}
	.carousel.next .list .item:nth-child(1) img{
		width: 150px;
		height: 220px;
		position: absolute;
		bottom: 80px;
		left: 50%;
		border-radius: 30px;
		animation: showImage .5s linear 1 forwards;
	}
	@keyframes outFrame{
		to{
			width: 150px;
			height: 220px;
			bottom: 80px;
			left: 50%;
			border-radius: 20px;
		}
	} 

}
@media screen and (max-height: 678px) {
    .carousel .list .item .content{
        padding-right: 0;
    }
    .carousel .list .item .content .title{
		text-align: center;
        font-size: 2.2em;
    }
	.carousel .list .item .topic{
		font-size: 1.3em;
		font-weight: bold;
		line-height: 1.3em;
	}
	.carousel .list .item .content{
		top: 15%;
		left: 50%;
		width: 80%;
		padding: 3%;
	}
	.event-lst{
		font-size: 1.3rem;
		left: 68%;
		top: 45%;
	}
	.list-container{
		padding: 25px;
		font-size: 0.7rem;
		width: 9rem;
	}
	.thumbnail{
		bottom: 10px;
	}
	.thumbnail .item{
		width: 100px;
		height: 150px;
	}
	.thumbnail{
		gap: 15px;
	}
	.thumbnail .item .content{
		font-size: 10px;
		text-align: left;
	}
	.carousel.next .list .item:nth-child(1) img{
		width: 100px;
		height: 150px;
		position: absolute;
		bottom: 10px;
		left: 50%;
		border-radius: 30px;
		animation: showImage .5s linear 1 forwards;
	}
	@keyframes outFrame{
		to{
			width: 100px;
			height: 150px;
			bottom: 10px;
			left: 50%;
			border-radius: 20px;
		}
	}

}

.prev-img{
    transform: rotate(180deg);
    max-width: 60px;
}
.next-img{
    max-width: 60px;
}